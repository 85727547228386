body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway';
  box-sizing: border-box;
}

body * {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0px;
  padding: 0px;
  font-family: 'Source Sans Pro';
  font-weight: normal;
}


button:hover {
  cursor: pointer;
}