#contacts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.map-and-address {
    margin-top: 36px;
    text-align: center;
    border: 1px solid lightgray;
    padding-bottom: 8px;
    min-width: 240px;
    max-width: 33%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.map-and-address:hover {
    cursor: pointer;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);;
}

.map-and-address img {
    padding-bottom: 8px;    
    width: 100%;
}

#location-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 16px;
}

@media screen and (min-width: 512px) {
    .map-and-address {
        margin-right: 16px;
    }
}

@media screen and (max-width: 512px) {
    .map-and-address {
        min-width: 100%;
    }
}
