#router-container {
  min-height: calc(100vh - 56px);
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.route-container {
  padding: 16px;
  min-height: calc(100vh - 56px);
  min-width: 100%;
}